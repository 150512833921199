<template>
    <div class="content">
        <div class="grid tabletop">
            <div class="grid-left">采值维度</div>
            <div>当前值</div>
            <div>标准值</div>
            <div>预警值</div>
            <div>达标结果</div>
        </div>
        <div class="overheight">
            <div class="grid grid-list" v-for="(item,index) in newarr" :key="index">
                <div class="grid-left">
                    <img :src="require('@/assets/img/' + item.img + '.png')" alt="">
                    <div>
                        <div>{{ item.name }}</div>
                        <div class="hui" v-if="item.info">{{ item.info }}</div>
                    </div>
                </div>
                <div class="redLiner">{{ item.value || '/' }}</div>
                <div>{{ item.standard }}</div>
                <div>{{ item.limits }}</div>
                <div class="flex-center grid-right" v-if="item.off == 'yes'">
                    <img src="@/assets/img/stand.png" alt="">
                    <div class="bluetext">达标</div>
                </div>
                <div class="flex-center grid-right" v-else-if="item.off == 'no'">
                    <img src="@/assets/img/stand2.png" alt="">
                    <div class="redLiner">未达标</div>
                </div>
                <div class="redLiner" v-else>/</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "tableData",
    props: {
        arr: {
            type: Array,
        },
    },
    data() {
        return {
            newarr:[]
        }
    },
    watch:{
        arr:{
            immediate:true,
            handler:function(newval){
                this.newarr=newval
            },
        }
    },
    methods: {

    },
};
</script>
<style src="@/assets/css/contentfixed.css" scoped></style>
<style lang="scss" src="@/assets/css/lowcarbon.scss" scoped></style>
<style scoped lang="scss"></style>