<template>
  <div class="lowcarbon">
    <transition name='left'>
      <div class="content_left">
        <div class="backbg" style="margin-bottom:18px">
          <div class="title">水质监测</div>
          <div class="tab">
            <div :class="dangactive.left1 == 0 ? 'avtive' : ''" @click="dangcheck('left1',0)">01</div>
            <div :class="dangactive.left1 == 1 ? 'avtive' : ''" @click="dangcheck('left1',1)">02</div>
          </div>
          <table-data :arr="left1"/>
        </div>
        <div class="backbg">
          <div class="title">人居环境监测</div>
          <div class="tab">
            <div :class="dangactive.left2 == 0 ? 'avtive' : ''" @click="dangcheck('left2',0)">01</div>
            <div :class="dangactive.left2 == 1 ? 'avtive' : ''" @click="dangcheck('left2',1)">02</div>
          </div>
          <table-data :arr="left2"/>
        </div>
      </div>
    </transition>
    <transition name='right'>
      <div class="content_right">
        <div class="backbg" style="margin-bottom:18px">
          <div class="title">厕所空气监测</div>
          <div class="tab">
            <div :class="dangactive.right1 == 0 ? 'avtive' : ''" @click="dangcheck('right1',0)">01</div>
            <div :class="dangactive.right1 == 1 ? 'avtive' : ''" @click="dangcheck('right1',1)">02</div>
            <div :class="dangactive.right1 == 2 ? 'avtive' : ''" @click="dangcheck('right1',2)">03</div>
            <div :class="dangactive.right1 == 3 ? 'avtive' : ''" @click="dangcheck('right1',3)">04</div>
          </div>
          <table-data :arr="right1"/>
        </div>
        <div class="backbg">
          <div class="title">月达标情况</div>
          <div class="tab">
            <div :class="useTypeactive == 0 ? 'avtive' : ''" @click="useTypecheck(0)">水质</div>
            <div :class="useTypeactive == 1 ? 'avtive' : ''" @click="useTypecheck(1)">人居</div>
            <div :class="useTypeactive == 2 ? 'avtive' : ''" @click="useTypecheck(2)">厕所</div>
          </div>
          <div class="content">
            <div class="year">
              <b>2023</b>年度
              <img src="@/assets/img/sanjiao.png" alt="">
            </div>
            <div class="bar" ref="bar"></div>
          </div>
        </div>
      </div>
    </transition>
    <transition name='center'>
      <div class="content_center">
        <div class="gaugebox">
          <div class="tab">
            <div :class="dangactive.bottom == 0 ? 'avtive' : ''" @click="watercheck(0)">01</div>
            <div :class="dangactive.bottom == 1 ? 'avtive' : ''" @click="watercheck(1)">02</div>
          </div>
          <div class="gauge" ref="gauge1" :class="dangactive.bottom != 0?'opacity':''"></div>
          <div class="gauge" ref="gauge2" :class="dangactive.bottom != 1?'opacity':''"></div>
          <div class="gaugedetail">
            <div class="line"></div>
            <div class="box2">{{ waterCriticalLevel}}</div>
            <div class="box3">警戒值</div>
            <div class="blueLiner">{{ water}}m</div>
            <div class="bluetext">{{ show_water}}</div>
          </div>
        </div>
        <div class="gaugeright">
          <div class="gaugetitle">
            <img src="@/assets/img/poise.png" alt="">
            <div>水位报警</div>
          </div>
          <div class="list">
            <div class="flex-between-center" v-for="(item, index) of warnrecord" :key="index"
              :class="index == -1 ? 'active' : ''">
              <div class="height">{{ item.waterLevel }}米｜{{ item.warnMessage }}</div>
              <div class="time">{{ item.createTime }}</div>
            </div>
            <div class="flex-between-center" v-if="warnrecord.length == 0">
              暂无报警
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="right">
      <div class="diong" v-if=" jkshow">
        <div class="top">
          <div class="title">{{ '监控' }}</div>
          <div class="pdclose" @click="closehkvido"></div>
        </div>
        <div class="jkshow">
            <hkvido ref="hkvido" v-if="jkshow" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  lowCarbonbase, lowCarbondevicelist, lowCarbondevicedetail,hikwaterlist,hikdetail
} from '@/api/permission'
import hkvido from '@/components/hkone.vue'
import tableData from "./tableData.vue";
var mychart = ""
export default {
  name: 'center',
  components: {
    tableData,hkvido
  },
  computed: {
    EntityData() {
      return this.$store.state.EntityData
    },
    show_water() {
      return Number(this.water)<5.5?'达标':'超标'
    }
  },
  watch: {
    EntityData(newval) {
      if(newval.businessEntityId!=null){
        this.getdetail(newval.businessEntityId)
      }else{
        this.$message('点位未设置ID')
      }
    }
  },
  data() {
    return {
      jkshow:false,
      loading:null,
      villageId: "",
      dangactive: {
        right1:0,
        left1:0,
        left2:0,
        bottom:0,
      },
      useTypeactive: 0,
      barlist: [
      ],
      left1: [
        { name: "PH值", info: "", img: "l11" ,query:'PH',limits:'<6.5,>8.5',standard:'7',min:'6.5',max:'8.5'},
        { name: "溶解氧", info: "(mg/L)", img: "l12",query:'溶解氧',limits:'<3',standard:'4',min:'3',max:'99999' },
        { name: "水温", info: "(°C)", img: "l13" ,query:'温度',limits:'>40',standard:'18',min:'-100',max:'40'},
        { name: "浊度", info: "(NTU)", img: "l14",query:'浊度',limits:'>5',standard:'2',min:'0',max:'5'},
        { name: "电导率", info: "(μs/cm)", img: "l15",query:'电导率',limits:'>3000',standard:'1000',min:'-1',max:'3000' },
      ],
      left2: [
        { name: "空气温度", info: " (℃)", img: "l21",query:'空气温度',limits:'>40',standard:'18',min:'-40',max:'40' },
        { name: "湿度", info: " (RH)", img: "l22",query:'空气湿度',limits:'>85',standard:'45',min:'-1',max:'85' },
        { name: "负氧离子", info: " (cm³)", img: "l23",query:'负氧离子',limits:'<400',standard:'1500',min:'400',max:'99999' },
        { name: "PM10", info: "(ug/m³)", img: "l11",query:'PM10',limits:'>150',standard:'25',min:'-1',max:'150' },
        { name: "PM2.5", info: "(ug/m³)", img: "l11",query:'PM2.5',limits:'>150',standard:'25',min:'-1',max:'150'  },
      ],
      right1: [
        { name: "检测温度", info: "(℃)", img: "r11",query:'温度',limits:'>40',standard:'18',min:'-40',max:'40'  },
        { name: "湿度", info: " (RH)", img: "r12" ,query:'湿度',limits:'>85',standard:'45',min:'-1',max:'85'},
        { name: "氨气", info: "(ppm)", img: "r13",query:'氨气',limits:'>0.2635',standard:'0',min:'-1',max:'0.2635' },
        { name: "PM10", info: "(ug/m³)", img: "r14",query:'PM10',limits:'>150',standard:'25',min:'-1',max:'150' },
        { name: "PM2.5", info: "(ug/m³)", img: "l11",query:'PM2.5',limits:'>150',standard:'25',min:'-1',max:'150' },
      ],
      warnrecord: [],//报警记录
      mychart: null,
      lowCar:[],
      water:"",
      waterCriticalLevel:"",
      waterlevel:[{
        name:'gauge1',
        val:2.1
      },{
        name:'gauge2',
        val:2
      },]
    };
  },
  methods: {
    getdetail(id){
      this.jkshow=true;
      hikdetail({id:id}).then(res=>{
        this.$refs.hkvido.realplay(document.location.protocol=='http:'?res.videoWsUrl:res.videoWssUrl)
      })
    },
    closehkvido(){
        this.$refs.hkvido.stopAllPlay()
        this.jkshow=false
    },
    getlowCarbonbase() {
     let loading = this.$loading({
          lock: true,
          text: '正在加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      lowCarbonbase({
        villageId: this.villageId
      }).then(res => {
        this.lowCar={
          left1:res.waterQualityList,
          left2:res.airList,
          right1:res.toiletAirList
        }
        this.dangcheck('left1',0,false)
        this.dangcheck('left2',0,false)
        this.dangcheck('right1',0,false)
        loading.close();
      }).catch(err=>{
        loading.close();
        console.log(err)
      })
    },
    getlowCarbondevicelist(useType) {
      lowCarbondevicelist({
        useType: useType,
        villageId: this.villageId
      }).then(res => {
        this.warnrecord = res||[];
      })
    },
    getlowCarbondevicedetail(useType) {
      lowCarbondevicedetail({
        useType: useType,
        villageId: this.villageId
      }).then(res => {
        this.barlist = res.list;
        this.creatchart();
      })
    },
    getwater(){
      hikwaterlist().then(res=>{
        this.water=res[0].waterSensorNowLevel;
        this.waterCriticalLevel=res[0].waterCriticalLevel;
        this.waterlevel[0].val=res[0].waterSensorNowLevel
        this.waterlevel[1].val=res[1].waterSensorNowLevel
        this.creatchartgauge(0);
        this.creatchartgauge(1);
      })
    },  
    watercheck(str){
        this.dangactive.bottom=str;
        this.water=this.waterlevel[str].val
        this.creatchartgauge(str)
    },
    dangcheck(str,val,off=true) {
      this.dangactive[str] = val; //选中的序号 用于显示
      let arr=this.lowCar[str][val] //选择序号的存起的数组
      if(off){
        console.log(arr,'设备')
      }
      if(!arr)arr={dataList:[]}
      let data={}
      this[str].forEach(e => {
        data=this.indexofDate(e,arr.dataList)
        this.$set(e,'off',data.off)
        this.$set(e,'value',data.val)
      });
    },
    indexofDate(obj,arr){
      let val='/',off='/',Num='';
      if(arr.length!=0){
        arr.forEach(e=>{
          if(e.registerName.indexOf(obj.query)!=-1)val=e.data 
        })
      }
      if(val!='/'){
        Num=Number(val);
        if(obj.min !='' && obj.max !=''){
          off=(Num>obj.min && Num<obj.max)?'yes':'no'
        }else if(obj.min !='' && obj.max ==''){
          off=(Num>obj.min)?'yes':'no'
        }else if(obj.min =='' && obj.max !=''){
          off=(Num<obj.max)?'yes':'no'
        }
      }
      return{
        off,
        val
      }
    },
    useTypecheck(val) {
      this.useTypeactive = val;
      this.getlowCarbondevicedetail(val);
    },
    creatchart() {
      let option;
      let dataAxis = []
      let data = []
      this.barlist.map((item) => {
        if (item.value != "暂无数据") {
          dataAxis.push(item.name);
          data.push(item.value);
        }
      })
      if(mychart==null){
        mychart.clear();
      }
      let that = this;
      mychart = this.$echarts.init(this.$refs.bar);
      option = {
        xAxis: {
          type: 'category',
          data: dataAxis,
          axisLabel: {
            color: '#fff',
            formatter: '{value}\n 月'
          },
        },
        yAxis: {
          type: 'value',
          color: '#ddd',
          axisLine: {
            show: false
          },
          axisLabel: {
            color: "#fff",
            formatter: '{value} %'
          },
          axisTick: {
            show: true
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            showBackground: false,
            label: {
              show: true,
              position: 'top',
              formatter: '{c} %',
              fontSize: 12,
              color: '#fff'
            },
            itemStyle: {
              color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#69C1FF' },
                { offset: 1, color: 'rgba(105, 193, 255, 0.2)' }
              ])
            },
            data: data
          }
        ]
      };
      mychart.setOption(option,true);
    },
    creatchartgauge(str) {
      let water=this.waterlevel[str]
      let option;
      let waterCriticalLevel=this.waterCriticalLevel
      let color = water.val> waterCriticalLevel? ["#FF916F", "#FC6332"] : ["#75FFFD", "#00BEBB"]
      let mychart = this.$echarts.init(this.$refs[water.name]);
      option = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '60%'],
            radius: '100%',
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: waterCriticalLevel*2 ,
            splitNumber: 2,
            itemStyle: {
              color: color[0]
            },
            progress: {
              show: true,
              width: 20
            },
            pointer: {
              show: false
            },
            // 坐标轴线
            axisLine: {
              lineStyle: {
                width: 20,
                color: [[1, 'rgba(255, 255, 255, 0.2)']],
              }
            },
            //刻度样式
            axisTick: {
              length: 2,
              distance: 10,
              splitNumber: 40,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            // 分隔线样式。--就是刻度
            splitLine: {
              show: false
              // length: 4,
              // lineStyle: {
              //   width: 2,
              //   color: '#999'
              // }
            },
            axisLabel: {
              show: false
              // distance: 25,
              // color: '#999',
              // fontSize: 12
            },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: water.val
              }
            ]
          },
          {
            type: 'gauge',
            center: ['50%', '60%'],
            radius: '100%',
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: waterCriticalLevel*2,
            itemStyle: {
              color: color[1]
            },
            progress: {
              show: true,
              width: 4
            },
            pointer: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value:  water.val
              }
            ]
          }
        ]
      };
      mychart.setOption(option);
    },
  },
  mounted() {
    this.villageId = this.$store.state.villageId;
    this.getlowCarbonbase();
    this.getlowCarbondevicelist(2);
    this.useTypecheck(0);
    this.getwater()
    this.$store.commit("SET_showtype", "1669171569498,1669171558218,1681874473854,1669171569498");
  },
}
</script>
<style src="@/assets/css/contentfixed.css" scoped>

</style>
<style lang="scss" src="@/assets/css/lowcarbon.scss" scoped>

</style>
<style lang="scss" scoped>
.diong {
  position: fixed;
  left: 27%;
  top: 11%;
  width: 900px;
  height: 680px;
  background: rgba(10, 42, 64, 0.9);
  border: 1px solid #064f89;
  .top {
    height: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: rgba(62, 106, 161, 0.6);
    .title {
      margin-left: 10px;
      font-size: 18px;
      color: #ffffff;
      font-weight: 600;
      line-height: 38px;
      background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .pdclose {
      width: 18px;
      height: 18px;
      cursor: pointer;
      float: right;
      position: relative;
      overflow: hidden;
      margin-top: 10px;
      opacity: 0.5;
      margin-right: 10px;
    }
  }
}
</style>